
















export default {
  props: ["icon", "header"],
};

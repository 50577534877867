<template>
  <v-form ref="form" v-model="form.valid" @submit.prevent="onSubmit">
    <slot></slot>
    <v-alert v-if="form.errorText" elevation="2" type="error" data-ui="form-error" icon="mdi-alert">
      {{ form.errorText }}
    </v-alert>
    <v-alert v-if="form.successText" elevation="2" type="success" data-ui="form-success" icon="mdi-check">
      {{ form.successText }}
    </v-alert>
    <div class="d-flex justify-space-between">
      <slot name="button-row"></slot>
      <v-btn type="submit" color="info" class="mt-2" :disable="form.isLoading" :loading="form.isLoading">
        <v-icon v-if="form.submitIcon" class="mr-2" data-ui="submit">{{ form.submitIcon }}</v-icon>
        {{ form.submitText }}
      </v-btn>
    </div>
  </v-form>
</template>
<script>
export default {
  props: {
    form: { type: Object, required: true },
  },
  mounted() {
    this.form.ref = this.$refs.form;
  },
  methods: {
    onSubmit() {
      this.form.errorText = "";
      this.form.successText = "";
      this.$refs.form.validate();
      this.form.onSubmit();
    },
    getFormState(onSubmit, submitText, submitIcon) {
      return {
        onSubmit,
        valid: true,
        isLoading: false,
        errorText: "",
        successText: "",
        submitIcon,
        submitText,
      };
    },
  },
};
</script>
